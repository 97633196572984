import { get, post, put, Delete } from "../axios";

// 权益分类-列表
export const equityClassifyList = (params) =>
  get("/web/equity/classify/list", params);

// 权益分类-新增
export const equityClassifyAdd = (params) =>
  post("/web/equity/classify/add", params);

// 权益分类-编辑
export const equityClassifyUpdate = (params) =>
  put("/web/equity/classify/update", params);

// 权益分类-删除
export const equityClassifyDelete = (id) =>
  Delete("/web/equity/classify/delete/" + id, "");
